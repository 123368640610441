import logo from "./logo.svg";
import "./App.css";
import SerialComponent from "./SerialComponent";
import SerialService from "./SerialCom";
import { createContext } from "react";
import SerialConnectionCom from "./ConnectionComponent";
import ChatComponent from "./Chat/ChatComponent";

export const SerialServiceContext = createContext({});

const serialService = new SerialService();

function App() {
  const user = { userId: 140262580, login: "gojo", password: "12345678" };
  const user2 = { userId: 140262586, login: "toji", password: "12345678" };
  const dialogID = "66e16574c317ef08dd2ee3c0";

  return (
    <div className="App">
      <div
        style={{
          display: "flex",
          justifyContent: "stretch",
          flexDirection: "row",
        }}
      >
        <ChatComponent user={user} dialogID={dialogID} opponent={user2} />
        <ChatComponent user={user2} dialogID={dialogID} opponent={user} />
      </div>
      {/* <SerialServiceContext.Provider
        value={{ serialService }} // provided as object
      >
        <SerialConnectionCom />
      </SerialServiceContext.Provider> */}
    </div>
  );
}

export default App;
