import { useState } from "react";

var APPLICATION_ID = 104254;
var AUTH_KEY = "ak_jBxLGFzfuNF-Bv-";
var AUTH_SECRET = "as_8K-ceUsYYOT7ZcT";
var ACCOUNT_KEY = "ack_iEwjDxFN3Kn-ao9Mu5To";
//66e030efb437f11df36e250c
const ChatComponent = ({ user, dialogID, opponent }) => {
  const [qbRef, setQBRef] = useState();
  const [writtentMsg, setWrittentMsg] = useState();

  console.log("user", user);

  const [messages, setMessages] = useState([]);

  //   let user = "login";
  //   let password = "parth@123";
  //   let userId = 140257891;
  //   let opponentId = 139798793;
  //   let dialogID = "66e030efb437f11df36e250c";

  console.log("messages", messages);
  console.log("messages", messages.length);

  function onMessage(userId, message) {
    console.log(`User ${userId} sent message: ${message}`);

    const json = JSON.stringify(message);

    console.log(`json ${json}`);

    setMessages((messages) => {
      return [
        ...messages,

        {
          u: userId,
          m: message.body,
        },
      ];
    });
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "50%",
      }}
    >
      <div>
        Cool
        <button
          onClick={() => {
            var QuickBlox = require("quickblox/quickblox.min").QuickBlox;
            const QB = new QuickBlox();

            QB.init(APPLICATION_ID, AUTH_KEY, AUTH_SECRET, ACCOUNT_KEY);

            var params = { login: user.login, password: user.password };
            console.log(params);
            QB.createSession(params, function (error, result) {
              console.log("createSession error", error);
              // callback function
            });

            var userCredentials = {
              userId: user.userId,
              password: user.password,
            };

            console.log("createSession", userCredentials);

            QB.chat.connect(userCredentials, function (error, contactList) {
              console.log("userCredentials error", error);
            });

            QB.chat.onMessageListener = onMessage;
            setQBRef(QB);
          }}
        >
          Start QB
        </button>
        <button
          onClick={() => {
            var params = {
              type: 3,
              occupants_ids: [opponent.userId],
            };

            console.log(params);
            qbRef.chat.dialog.create(params, function (error, dialog) {
              // callback function
              console.log("dialog error", error);
              if (!error) {
                console.log("Dialog created successfully", dialog);
                console.log("Dialog created successfully", dialog.id);
              }
            });
          }}
        >
          Create Dialog
        </button>
        <div>
          <input
            type="text"
            onChange={(e) => {
              setWrittentMsg(e.target.value);
            }}
          ></input>
          <button
            onClick={() => {
              var message = {
                type: "chat",
                body: writtentMsg,
                extension: {
                  save_to_history: 1,
                  dialog_id: dialogID,
                },
                markable: 1,
              };
              const messageData = qbRef.chat.send(opponent.userId, message);
              console.log("message", messageData);

              setMessages((messages) => {
                return [
                  ...messages,

                  {
                    u: user.userId,
                    m: writtentMsg,
                  },
                ];
              });

              setWrittentMsg("");
            }}
          >
            Send Message
          </button>
        </div>
      </div>
      <div>
        {messages.length != 0
          ? messages.map((message) => {
              return (
                <div>
                  {message.u === user.userId ? "You" : opponent.login}:{" "}
                  {message.m}
                </div>
              );
            })
          : ""}
      </div>
    </div>
  );
};

export default ChatComponent;
